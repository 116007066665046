import type { ServiceWorkerScriptState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';
import type { AppResourceState } from 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts';
import type { IServiceWorkerImportMap } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';

declare module 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts' {
    export interface IServiceWorkerScriptStates {
        getAll: () => Promise<Array<ServiceWorkerScriptState>>;
    }

    export interface IAppResourceStates {
        getAll: () => Promise<Array<AppResourceState>>;
    }

    export interface IServiceWorkerStateOptions {
        appId: string;
        entrypoint?: string;
        importMap?: IServiceWorkerImportMap;
    }

    export type ServiceWorkerScriptStates = IServiceWorkerScriptStates & Record<Exclude<string, keyof IServiceWorkerScriptStates>, Promise<ServiceWorkerScriptState | null>>;
    export type AppResourceStates = IAppResourceStates & Record<Exclude<string, keyof IAppResourceStates>, Promise<AppResourceState | null>>;

    export class ServiceWorkerState {
        static objectStoreDexieSchema: string;

        appId: string;
        requireServiceWorkerUpdate: boolean;
        requiredServiceWorkerScripts: Array<string | URL>;
        entrypoint: string;
        importMap: IServiceWorkerImportMap;
        readyForInstall: boolean;
        installed: boolean;
        failedToInstall: boolean;

        constructor(options: IServiceWorkerStateOptions);

        readonly serviceWorkerScriptStates: ServiceWorkerScriptStates;
        readonly appResourceStates: AppResourceStates;

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<ServiceWorkerState | null>;
        static clearCache(): void;
    }
}
